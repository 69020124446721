import React from "react";
import Footer from "../../components/layout/Footer";
import { LINKS } from "../../links/Links";
import { MoreButton } from "../../components/widgets/button/Buttons";
import Card from "../../components/widgets/card/Card";
import style from "./Index.module.css";
import Slider from "../../components/widgets/slider/Slider";
import Menu from "../../components/widgets/menu/Menu";

const Index = () => {
  return (
    <>
      <Menu />
      <Slider />
      <div className={style.about}>
        <h2 className={`${style.aboutTitle}`}>ECOBIA</h2>
        <p className={style.aboutText}>
          Join us on our journey! Pledge for your Organic Food Waste Composting
          Machine via ECOBIA and receive unique discounts.
        </p>
        <MoreButton
          url="/products"
          btn_style="outlineWhite"
          text="GET YOUR COMPOSTER"
        />
      </div>
      {/* <div>
        <img
          src="	https://togohb.com/wp-content/uploads/2022/03/Banner-02-2048x350.jpg"
          alt="image of green vegetation"
          className={style.vegetation}
        />
      </div> */}
      <h2
        className={`${style.row} ${style.justifyContentCenter} ${style.reuseTitle}`}
      >
        How can we reuse the By-Product
      </h2>
      <div className={`${style.row} ${style.justifyContentCenter}`}>
        <Card
          img="https://togohb.com/wp-content/uploads/2022/03/Natural-Fertilizer-01.jpg"
          text="You can use the by-product to fertilize plants and trees to offer a better growth rate. You can also use the water filtered by ECOBIA to water the plants."
          url=""
          title="Natural Fertilizer"
        />
        <Card
          img="https://togohb.com/wp-content/uploads/2022/03/Animal-Feed-01.jpg"
          text="Since the items processed in ECOBIA organic waste composting machines are mostly food and organic waste, they can be used to make animal feed."
          url=""
          title="Animal Feed"
        />
        <Card
          img="https://togohb.com/wp-content/uploads/2022/03/Bio-Fuel-01.jpg"
          text="It can be used as a Bio Fuel. Instead of using wood, you can compress the compost generated by ECOBIA machines to make a burnable bio-fuel."
          url=""
          title="Bio Fuel"
        />
      </div>
      <div className={style.waste_converted}>
        <div className={style.tons}>
          <h1 className={style.number}>8,695,859</h1>
          <p className={style.numberDetails}>
            tons organic wastes have converted to compost by ECOBIA
          </p>
        </div>
        <div>
          <p>
            Did you know we waste 15,0000 tonnes of
            <br className={style.hide} /> food waste each day?
            <br className={style.hide} /> That’s enough food to feed
          </p>
          <h1 className={style.people}>22 million people!</h1>
        </div>
      </div>
      <div className={style.tech}>
        <img
          src="https://togohb.com/wp-content/uploads/2021/07/5c6d738c82ef301d23ac3acf_ecoguardians_generalimage3.jpg"
          alt="Plant and bulb image"
          className={`${style.col6} ${style.tech_img}`}
        />
        <div className={`${style.col6} ${style.p3}`}>
          <h3 className={style.title}>
            Don’t just throw food waste into the rubbish bin – it can still be
            useful. With ECOBIA technology, almost all organic waste can be
            recycled into something valuable – compost.
          </h3>
          <p className={style.text}>
            ECOBIA founded in 2008, has since grown into a worldwide company
            that has completed projects around the world. We work with some of
            the world’s leading brands to implement a zero-waste solution in
            their operations. Our clients are able to minimize their waste to
            landfills, reduce their greenhouse gas emissions and thus reduce
            their overall environmental footprint.
          </p>
          <span className={style.center}>
            <MoreButton url={LINKS.ABOUT} btn_style="btn" text="Read More ++" />
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
