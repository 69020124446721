export const LINKS = {
  HOME: "home",
  PRODUCTS: "products",
  HOME_COMPOSTER: "",
  COMMERCIAL_COMPOSTER: "",
  NEWS: "news",
  ABOUT: "about",
  CONTACT: "contact",
  ORGANIC_WASTE_SHREDDER: "",
  OIL_WATER_SEPARATOR: "",
  FOOD_WASTE_COMPOSTING: "",
  SHREDDER_DEHYDRATOR: "",
};
