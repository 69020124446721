import React from "react";
import {
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
} from "../../img/Image";
import style from "./Product.module.css";

const productList = [
  {
    img: product1,
    title: "EB5-SP",
    capacity: ["5 kg/day", "1,825 kgs/year", "Single phase 220v"],
    applications: ["Takeaway", "Home use", "Small Office"],
    category: "home",
  },
  {
    img: product2,
    title: "EB25-SP",
    capacity: ["25 kg/day", "9,000 kgs/year", "Single phase 220v"],
    applications: [
      "Small Restaurants",
      "Small Supermarkets",
      "Delicatessen",
      "Offices",
    ],
    category: "commercial",
  },
  {
    img: product3,
    title: "EB50-SP",
    capacity: ["50 kg/day", "18,000 kgs/year", "Single phase 220v"],
    applications: [
      "Large Restaurants",
      "Large Supermarkets",
      "Delicatessen",
      "Offices Blocks",
      "And many more...",
    ],
    category: "commercial",
  },
  {
    img: product4,
    title: "EB100-3PSP",
    capacity: [
      "100 kg/day",
      "36,000 kgs/year",
      "3 phase/ Single Phase (380v/220v)",
    ],
    applications: [
      "Large Restaurants",
      "Large Supermarkets",
      "Hotels",
      "Hospitals",
      "Nursing Homes",
      "Large Office Blocks",
    ],
    category: "commercial",
  },
  {
    img: product5,
    title: "EB200-3PBL/SHR",
    capacity: ["200 kg/day", "72,000 kgs/year", "3 phase 380v"],
    applications: [
      "Large Restaurants",
      "Large Supermarkets",
      "Large Hotels",
      "Hospitals",
      "Colleges",
      "Nursing Homes",
      "Large Office Blocks",
    ],
    category: "commercial",
  },
  {
    img: product6,
    title: "EB300-3PBL/SHR",
    capacity: ["300 kg/day", "109,000 kgs/year", "3 phase 380v"],
    applications: [
      "Large Restaurants",
      "Large Supermarkets",
      "Large Hotels",
      "Hospitals",
      "Colleges",
      "Nursing Homes",
      "Large Office Blocks",
    ],
    category: "commercial",
  },
  {
    img: product7,
    title: "EB500-3PBL/SHR",
    capacity: ["500 kg/day", "182,500 kgs/year", "3 phase 380v"],
    applications: [
      "Large Restaurants",
      "Large Supermarkets",
      "Large Hotels",
      "Airports, Hospitals",
      "Colleges, Universities",
      "Nursing Homes",
      "Large Office Blocks",
    ],
    category: "commercial",
  },
  {
    img: product8,
    title: "EB1000-3PBL/SHR",
    capacity: ["2,000 kg/day", "730,000 kgs/year", "3 phase 380v"],
    applications: [
      "Large Restaurants",
      "Large Hotels",
      "Large Supermarkets",
      "Colleges, Universities",
      "Airports, Hospitals",
      "Nursing Homes",
      "Co Councils",
      "Large Office Blocks",
    ],
    category: "commercial",
  },
];

const Product = ({ category }) => {
  return (
    <>
      {productList.map((product) => {
        if (product.category === category || category === "all") {
          return (
            <div className={style.product}>
              <img src={product.img} className={style.productImg} />
              <div>
                <h2 className={style.title}>{product.title}</h2>
                <ul className={style.productUl}>
                  {product.capacity.map((capacity) => (
                    <li>{capacity}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className={style.pt2}>APPLICATIONS</h2>
                <ul className={style.productUl}>
                  {product.applications.map((application) => (
                    <li>{application}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default Product;
