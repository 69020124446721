import React from "react";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/layout/Footer";
import Product from "../../components/product/Product";
import Menu from "../../components/widgets/menu/Menu";
import style from "./Commercial.module.css";

const Household = () => {
  return (
    <>
      <Menu />
      <div className={style.mtop}></div>
      <img
        src="https://togohb.com/wp-content/uploads/2022/03/HOME-COMPOSTER-BANNER-01.jpg"
        alt="composting banner"
        className={style.img}
      />
      <p className={`${style.p} ${style.textCenter}`}>
        ECOBIA's home composting machine is an electric composting unit ideal
        for small-scale waste generators like homes, coffee shops, schools, and
        offices. The household composting machine utilizes microbes to compost
        food waste in 24 hours, producing a nutrient-rich soil amendment that
        can be used in gardens.
      </p>

      <div className={`${style.flexCenter} ${style.mflexCenter}`}>
        <div
          className={`${style.col4} ${style.mcol4} ${style.textCenter} ${style.iconText}`}
        >
          <div className={style.flexCenter}>
            <img
              src="https://togohb.com/wp-content/uploads/2021/07/CONVENIENT-150x150.jpg"
              alt="clock "
              className={style.imgIcon}
            />
          </div>
          CONVENIENT
          <p>ECOBIA works automatically and processes organic waste easily</p>
        </div>
        <div
          className={`${style.col4} ${style.mcol4} ${style.textCenter} ${style.iconText}`}
        >
          <div className={style.flexCenter}>
            <img
              src="https://togohb.com/wp-content/uploads/2021/07/CLEAN-150x150.jpg"
              alt="landfill "
              className={style.imgIcon}
            />
          </div>
          CLEAN
          <p>Our 3 step system provides odorless and safe handling </p>
        </div>
        <div
          className={`${style.col4} ${style.mcol4} ${style.textCenter} ${style.iconText}`}
        >
          <div className={style.flexCenter}>
            <img
              src="https://togohb.com/wp-content/uploads/2021/07/RELIABLE-150x150.jpg"
              alt="reduce disposal cost "
              className={style.imgIcon}
            />
          </div>
          RELIABLE
          <p>
            High quality materials and the best technology ensures durability
          </p>
        </div>
      </div>
      <div className={`${style.flexCenter} ${style.mflexCenter}`}>
        <div
          className={`${style.col4} ${style.mcol4} ${style.textCenter} ${style.iconText}`}
        >
          <div className={style.flexCenter}>
            <img
              src="https://togohb.com/wp-content/uploads/2021/07/VALUABLE-150x150.jpg"
              alt="minimal odour "
              className={style.imgIcon}
            />
          </div>
          VALUABLE
          <p>Nourish your plants with fresh and healthy compost</p>
        </div>
        <div
          className={`${style.col4} ${style.mcol4} ${style.textCenter} ${style.iconText}`}
        >
          <div className={style.flexCenter}>
            <img
              src="https://togohb.com/wp-content/uploads/2021/07/CLIMATE-FRIENDLY-150x150.jpg"
              alt="pest deterrent"
              className={style.imgIcon}
            />
          </div>
          CLIMATE FRIENDLY
          <p>Produce less waste and reduce the emission of methane gas</p>
        </div>
      </div>

      <img
        src="https://togohb.com/wp-content/uploads/2021/07/Home-Composter.jpg"
        alt="composting banner"
        className={style.img}
      />

      <p className={style.p}>
        TG-CC-2 home composting machine can compost up to 5 kg of food waste per
        day, turning waste into a valuable resource that can be used to
        fertilize plants or grow fruits and vegetables. With an 80-90% reduction
        rate, compost will only need to be removed from the unit on a weekly
        basis. ECOBIA's electric composter helps users divert food waste from
        the landfill, reduce the amount of methane gas generated from organic
        waste, and decrease carbon emissions.
      </p>

      <Product category="home" />
      <img
        src="https://togohb.com/wp-content/uploads/2021/08/Home-Composter-2.jpg"
        alt="composting banner"
        className={style.img}
      />

      <p className={style.p}>
        Smart and fast home composting machine that transforms kitchen waste and
        food scraps into 100% organic ready-to-use compost for indoor or outdoor
        plants, gardens, and backyards. This revolutionary device has one-button
        simplicity, is super-fast, and is silent. It’s the best way to eliminate
        kitchen mess and odors, reduce waste, and provide an eco-friendly
        solution for leftovers.
      </p>
      <p className={style.p}>
        Household composting machine’s super-fast processing speed sets it apart
        from the competition and means you can avoid waste by quickly turning
        kitchen scraps into compost. It helps keep food out of the landfill for
        a more earth-friendly lifestyle and transforms nearly all food waste
        into dry, 100% organic ready-to-use compost within 24 hours.
      </p>
      <p className={style.p}>
        Ecobia is a best Air purifier in Europe. It is good for those with
        respiratory problems. It is simply because the most unpleasant fraction
        of the waste stream the food waste is being. You can do food waste
        converts into Compost every day through the hopper. Add absorbent and
        microbial composting agents along with the waste. We are providing top
        converter machines for the best interment. You are different benefit
        organic converter for plants. You will not have to water your plants as
        much saving on your water bill. Compost can act as a natural evaporation
        barrier for your garden helping it retain water and reduce the need to
        water.
      </p>
      <div className={`${style.p} ${style.my2} ${style.mtXs5}`}>
        <h2>INFORMATION REQUEST </h2>
        <p>
          Please fill in the following form, we will respond to your request in
          24 hours.
        </p>
      </div>
      <div className={`${style.row} ${style.p} ${style.mb}`}>
        <div className={`${style.col6}`}>
          <Contact />
        </div>
        <img
          src="https://togohb.com/wp-content/uploads/2021/09/Organic-Waste-Disposal-Solutions-1.jpg"
          alt="make enquiry"
          className={`${style.contactImg} ${style.col6}`}
        />
      </div>
      <Footer />
    </>
  );
};

export default Household;
