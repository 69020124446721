import React from "react";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/layout/Footer";
import Menu from "../../components/widgets/menu/Menu";
import style from "./ContactPage.module.css";
const ContactPage = (props) => {
  return (
    <>
      <Menu />
      <div className={style.top}></div>
      <img
        src="https://togohb.com/wp-content/uploads/2021/11/Contact-TOGO-Banner-01.jpg"
        alt="composting banner"
        className={style.img}
      />
      <div className={`${style.row} ${style.p} ${style.mb}`}>
        <div className={`${style.col6} ${style.form}`}>
          <Contact />
        </div>
        <div className={`${style.map} ${style.col6}`}>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width="100%"
                height="400px"
                id="gmap_canvas"
                // src="https://maps.google.com/maps?q=21F%20Grace%20Park%20Grove,%20Drumcondra&t=&z=13&ie=UTF8&iwloc=&output=embed"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.1230997461007!2d-6.285880784290445!3d53.34105257997789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670d5a845ad825%3A0x5467c3fb93052477!2sThe%20Guinness%20Enterprise%20Centre%20(GEC)!5e0!3m2!1sen!2sie!4v1675253030040!5m2!1sen!2sie"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ContactPage;
