import React from "react";
import Footer from "../../components/layout/Footer";
import { MoreButton } from "../../components/widgets/button/Buttons";
import Menu from "../../components/widgets/menu/Menu";
import Product from "../../components/product/Product";
import style from "./Commercial.module.css";
import Contact from "../../components/contact/Contact";

const AllProducts = () => {
  return (
    <>
      <Menu />
      <div className={style.top}></div>
      <h2 style={{ padding: "20px" }}>PRODUCT RANGE</h2>
      <Product category="all" />

      {/* video */}
      <div style={{ backgroundColor: "#e9e9e9", padding: "50px 30px" }}>
        <div className={`${style.p} ${style.my2} ${style.mtXs5}`}>
          <h2>INFORMATION REQUEST </h2>
          <p>
            Please fill in the following form, we will respond to your request
            in 24 hours.
          </p>
        </div>
        <div className={`${style.row} ${style.p} ${style.mb}`}>
          <div className={`${style.col6}`}>
            <Contact />
          </div>
          <img
            src="https://togohb.com/wp-content/uploads/2021/09/Organic-Waste-Disposal-Solutions-1.jpg"
            alt="make enquiry"
            className={`${style.contactImg} ${style.col6}`}
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AllProducts;
